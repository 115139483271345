import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from '../../i18n';

import useAuth from '../../State/Auth/useAuth';
import usePermissions from '../../State/Permissions/usePermissions';

import NavigationListItem, { NavigationItem } from './NavigationListItem';
import { NavList, NavItem, NavLink } from './wrapper';
import { Skeleton } from '../Skeleton';
import { userCanView } from './userCanView';
import { HeaderCtx } from './HeaderContext';

import {
  IconBarChart,
  IconDoughnutChart,
  IconStaircase,
  IconFiles,
  IconUser,
  IconCloud,
  IconRecycle,
  IconWorkplaces,
} from '../Icon';
import { ItemScheduled } from './Widgets';

const navigationListItems: NavigationItem[] = [
  {
    title: i18n.t('navigation_order_title'),
    subNav: [
      {
        title: i18n.t('navigation_services_title'),
        path: '/services/',
        description: i18n.t('navigation_services_description'),
        icon: <IconRecycle $outlined size="24px" />,
      },
      {
        title: i18n.t('navigation_scheduled_title'),
        path: '/scheduled/',
        description: i18n.t('navigation_scheduled_description'),
        widget: <ItemScheduled isSmall={true} />,
      },
      {
        title: i18n.t('navigation_workplaces_title'),
        path: '/workplaces/',
        description: i18n.t('navigation_workplaces_description'),
        icon: <IconWorkplaces $outlined size="24px" />,
        authority: ['clientAdmin'],
      },
      {
        title: i18n.t('order_history'),
        path: '/orders/',
        description: i18n.t('order_history_description'),
        icon: <IconWorkplaces $outlined size="24px" />,
      },
    ],
  },
  {
    title: i18n.t('navigation_trends_title'),
    subNav: [
      {
        title: i18n.t('trends_title'),
        path: '/trends/',
        permission: 'GraphMaterialsByMonth',
        description: i18n.t('navigation_trends_description'),
        icon: <IconBarChart $outlined size="24px" />,
      },
      {
        title: i18n.t('waste_hierarchy_title'),
        path: '/waste-hierarchy',
        description: i18n.t('navigation_waste_hierarchy_description'),
        icon: <IconStaircase $outlined size="24px" />,
      },
      {
        title: i18n.t('workplace_analysis_title'),
        path: '/workplace-analysis',
        description: i18n.t('navigation_workplace_analysis_description'),
        icon: <IconBarChart $outlined size="24px" />,
      },
      {
        title: i18n.t('scope3_title'),
        path: '/scope3',
        permission: 'SpreadsheetStatistics',
        description: i18n.t('navigation_scope3_description'),
        icon: <IconDoughnutChart $outlined size="24px" />,
      },
    ],
  },
  {
    title: i18n.t('navigation_reports_title'),
    subNav: [
      {
        title: i18n.t('navigation_report_statistics_title'),
        path: '/reports/statistics',
        permission: 'SpreadsheetStatistics',
        description: i18n.t('navigation_report_statistics_description'),
        icon: <IconFiles $outlined size="24px" />,
      },
      {
        title: i18n.t('navigation_report_waste_title'),
        path: '/reports/waste',
        permission: 'SpreadsheetStatistics',
        description: i18n.t('navigation_report_waste_description'),
        icon: <IconFiles $outlined size="24px" />,
      },
      {
        title: i18n.t('navigation_sustainability_report_title'),
        path: '/sustainability-report',
        authority: ['clientAdmin', 'userAdmin'],
        description: i18n.t('navigation_sustainability_report_description'),
        icon: <IconFiles $outlined size="24px" />,
      },
    ],
  },
  {
    title: i18n.t('navigation_estates_title'),
    permission: 'SpreadsheetEstatesStatistics',
    subNav: [
      {
        title: i18n.t('estates_h1'),
        path: '/estates',
        description: i18n.t('navigation_estates_description'),
        icon: <IconFiles $outlined size="24px" />,
        exact: true,
      },
      {
        title: i18n.t('estate_analysis'),
        path: '/estate-analysis',
        description: i18n.t('navigation_estates_analysis_description'),
        icon: <IconFiles $outlined size="24px" />,
      },
      {
        title: i18n.t('estates_scope3_title'),
        path: '/estates/scope3',
        description: i18n.t('navigation_estates_scope3_description'),
        icon: <IconDoughnutChart $outlined size="24px" />,
      },
    ],
  },
  {
    title: i18n.t('navigation_administration_title'),
    subNav: [
      {
        title: i18n.t('navigation_users_title'),
        path: '/users',
        authority: ['clientAdmin', 'userAdmin'],
        description: i18n.t('navigation_users_description'),
        icon: <IconUser $outlined size="24px" />,
      },
      {
        title: i18n.t('user_admins_title'),
        path: '/remondis-admins',
        authority: ['userAdmin'],
        description: i18n.t('user_admins_description'),
        icon: <IconUser $outlined size="24px" />,
      },
      {
        title: i18n.t('api_client'),
        path: '/admin/api-clients',
        authority: ['clientAdmin'],
        description: i18n.t('navigation_api_clients_description'),
        icon: <IconCloud $outlined size="24px" />,
      },
      {
        title: i18n.t('invoices_title'),
        path: '/invoices',
        authority: ['clientAdmin'],
        description: i18n.t('invoices_description'),
        icon: <IconFiles $outlined size="24px" />,
      },
    ],
  },
];

interface NavigationListProps {
  userAdmin: boolean;
  clientAdmin: boolean;
}

const NavigationList = ({ userAdmin, clientAdmin }: NavigationListProps) => {
  const { token } = useAuth();
  const { pathname } = useLocation();
  const { permissions, getPermissions } = usePermissions();
  const { handleNavigation } = useContext(HeaderCtx);
  function getParsedMenu(): NavigationItem[] {
    const filteredItems = navigationListItems
      .map((item) => {
        const copyItem = { ...item };
        // Don't render empty subNavs
        if (item.subNav) {
          copyItem.subNav = item.subNav.filter((subNavItem) =>
            userCanView({ item: subNavItem, userAdmin, clientAdmin, permissions })
          );

          return copyItem.subNav.length > 0 &&
            userCanView({ item, userAdmin, clientAdmin, permissions })
            ? copyItem
            : undefined;
        }
        return userCanView({ item, userAdmin, clientAdmin, permissions }) ? copyItem : undefined;
      })
      .filter((item): item is NavigationItem => !!item);
    return filteredItems;
  }

  useEffect(() => {
    if (!permissions && token) getPermissions();
  }, [token]);

  if (!permissions) {
    return (
      <NavList>
        <NavItem>
          <NavLink to="#">
            <Skeleton $height="24px" $width="40px" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="#">
            <Skeleton $height="24px" $width="70px" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="#">
            <Skeleton $height="24px" $width="90px" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="#">
            <Skeleton $height="24px" $width="65px" />
          </NavLink>
        </NavItem>
      </NavList>
    );
  }

  return (
    <NavList>
      {getParsedMenu().map((navigation, i) => (
        <NavigationListItem
          key={`${navigation.path}-${i}`}
          navigation={navigation}
          pathname={pathname}
          onClick={handleNavigation}
        />
      ))}
    </NavList>
  );
};

export default NavigationList;
